import { createContext, useEffect, useState } from "react";
import { useManufacturerApi } from "../../../api/manufacturerApi";
import { REPORT_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import useReportContext from "../useReportContext";
import { useInspectionTypeApi } from "../../../api/inspectionTypeApi";
import { useReportApi } from "../../../api/reportApi";
import { ReportType } from "../../../utils/reportType";
import { CommentsDisabled } from "@mui/icons-material";

const PlaygroundRepoerEquipmentsContext = createContext({});

let defaultInspectionType = {};

export const PlaygroundReportEquipmentsProvider = ({ children }) => {
    const { getPrioriyManufacturers } = useManufacturerApi();
    const { getInspectionTypesForReportType } = useInspectionTypeApi();
    const { getReportEquipments } = useReportApi();
    const { report, updateReport, setNextEnabled } = useReportContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDialog, setShowDialog] = useState(false);
    const [isEquipmentSelection, setEquipmentSelection] = useState(false);
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [overrideManufacturer, setOverrideManufacturer] = useState(
        report.baseData.priorityManufacturer != null
    );
    const [selectedPriorityManufacturer, setSelectedPriorityManufacturer] =
        useState(report.baseData.priorityManufacturer);
    const [priorityManufacturers, setPriorityManufacturers] = useState([]);
    const [editedId, setEditedId] = useState(null);

    const [reportEquipments, setReportEquipments] = useState([]);

    useEffect(() => {
        fetchInspectionTypes();
    }, []);

    useEffect(() => {
        report.baseData.priorityManufacturer = selectedPriorityManufacturer;
    }, [selectedPriorityManufacturer, setSelectedPriorityManufacturer]);

    useEffect(() => {
        if (priorityManufacturers.length === 0 && overrideManufacturer) {
            fetchPrioriyManufacturers();
        } else {
            setSelectedPriorityManufacturer(null);
        }
    }, [overrideManufacturer, setOverrideManufacturer]);

    useEffect(() => {
        if (report.baseData.id) {
            fetchReportEquipments();
        }
    }, [report]);

    useEffect(() => {
        const nextEnabled =
            reportEquipments.length > 0 &&
            (!overrideManufacturer ||
                (overrideManufacturer && selectedPriorityManufacturer))
                ? true
                : false;
        setNextEnabled(nextEnabled);
    }, [
        reportEquipments,
        setReportEquipments,
        overrideManufacturer,
        setOverrideManufacturer,
        selectedPriorityManufacturer,
        setSelectedPriorityManufacturer,
    ]);

    useEffect(() => {
        const defaultInspectionTypes = inspectionTypes.find(
            (item) => item.default
        );
        defaultInspectionType = defaultInspectionTypes;
    }, [inspectionTypes, setInspectionTypes]);

    function changeInspectionType(reportEquipment, inspectionType) {
        if (report.baseData.reportType.code === ReportType.ENTERTAINMENT.code) {
            const newReportEquipment = {
                ...reportEquipment,
                inspectionType: inspectionType,
                reportEquipmentId: 0,
            };

            setReportEquipments([newReportEquipment]);
        } else {
            reportEquipment.inspectionType = inspectionType;
            const index = reportEquipments.findIndex(
                (item) =>
                    item.reportEquipmentId === reportEquipment.reportEquipmentId
            );
            let equipments = [...reportEquipments];
            equipments[index] = reportEquipment;
            setReportEquipments(equipments);
        }
    }

    function deleteEquipment(reportEquipmentId) {
        const equipments = reportEquipments.filter(
            (item) => reportEquipmentId !== item.reportEquipmentId
        );
        setReportEquipments(equipments);
    }

    function addEquipments(input) {
        var inspectionType = defaultInspectionType;
        if (report.baseData.reportType.code === ReportType.ENTERTAINMENT.code) {
            const equipments = input.flat().map((item, index) => ({
                equipment: item,
                inspectionType: inspectionType,
                reportEquipmentId: index,
            }));
            setReportEquipments(equipments);
        } else {
            var mergedEquipments = [];
            mergedEquipments.push(reportEquipments);

            if (reportEquipments.length > 0) {
                inspectionType = reportEquipments.slice(-1)[0].inspectionType;
            }
            const newEquipments = input.map((item, index) => ({
                inspectionType: inspectionType,
                equipment: item,
            }));
            mergedEquipments.push(newEquipments);
            mergedEquipments = mergedEquipments.flat().map((item, index) => ({
                ...item,
                reportEquipmentId: index,
            }));
            setReportEquipments(mergedEquipments);
        }
    }

    function fetchReportEquipments() {
        getReportEquipments(report.baseData.id, (response) => {
            const equipments = response.data.reportEquipments.map(
                (item, index) => ({
                    ...item,
                    reportEquipmentId: index,
                })
            );
            setReportEquipments(equipments);
        });
    }

    function fetchInspectionTypes() {
        getInspectionTypesForReportType(
            report.baseData.reportType,
            (response) => {
                setInspectionTypes(response.data.inspectionTypes);
            }
        );
    }

    function fetchPrioriyManufacturers() {
        getPrioriyManufacturers((response) => {
            setPriorityManufacturers(response.data.manufacturers);
        });
    }

    async function saveReportEquipments(comment) {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            const equipments = reportEquipments.map((item) => {
                return {
                    id: item.id,
                    equipmentId: item.equipment.id,
                    inspectionType: item.inspectionType.id,
                    certificateNumber: item.equipment.certificateNumber,
                    certificateDate: item.equipment.certificateDate,
                    comment: comment,
                };
            });
            const request = {
                equipments: equipments,
                priorityManufacturer: selectedPriorityManufacturer?.id,
            };
            const response = await axiosPrivate.put(
                `${REPORT_URL}/${report.baseData.id}/equipments`,
                JSON.stringify(request),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            updateReport("reportEquipments", response.data.reportEquipments);
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    }

    return (
        <PlaygroundRepoerEquipmentsContext.Provider
            value={{
                defaultInspectionType,
                inspectionTypes,
                addEquipments,
                showDialog,
                setShowDialog,
                reportEquipments,
                setReportEquipments,
                fetchReportEquipments,
                editedId,
                setEditedId,
                isEquipmentSelection,
                setEquipmentSelection,
                changeInspectionType,
                deleteEquipment,
                saveReportEquipments,
                overrideManufacturer,
                setOverrideManufacturer,
                priorityManufacturers,
                setPriorityManufacturers,
                selectedPriorityManufacturer,
                setSelectedPriorityManufacturer,
            }}
        >
            {children}
        </PlaygroundRepoerEquipmentsContext.Provider>
    );
};

export default PlaygroundRepoerEquipmentsContext;
