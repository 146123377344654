import { FormControl, MenuItem, Select } from "@mui/material";
import usePlaygroundReportEquipmentsContext from "./usePlaygroundReportEquipmentsContext";
import { useGridApiContext } from "@mui/x-data-grid";

export function SelectInspectionTypeCell(props) {
    const { id, field } = props;
    const apiRef = useGridApiContext();
    const { inspectionTypes, changeInspectionType } =
        usePlaygroundReportEquipmentsContext();

    const handleChange = async (props, event) => {
        const inspectionType = event.target.value;
        changeInspectionType(props.row, inspectionType);
        await apiRef.current.setEditCellValue({
            id,
            field,
            value: inspectionType,
        });
        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <FormControl fullWidth>
            <Select
                value={props.row.inspectionType}
                onChange={(e) => handleChange(props, e)}
                renderValue={(item) => item.name}
            >
                {inspectionTypes.map((inspectionType) => (
                    <MenuItem key={inspectionType.id} value={inspectionType}>
                        {inspectionType.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
