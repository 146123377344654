import { createContext, useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useGeneralErrorContext from "../../../common/generalerrorsnackbar/useGeneralErrorContext";
import { REPORT_URL } from "../../../../api/Url";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";
import useReportContext from "../../useReportContext";
import useFullScreenProgressBarContext from "../../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import { useReportApi } from "../../../../api/reportApi";
import { useEquipmentTypeRestrictionApi } from "../../../../api/equipmentTypeRestrictionApi";

const ReportEquipmentRestrictionsContext = createContext({});

export const ReportEquipmentRestrictionsProvider = ({ children }) => {
    const { getEquipmentTypeRestrictionsByType } =
        useEquipmentTypeRestrictionApi();
    const { getReportEquipmentRestrictions, deleteReportEquipmentRestriction } =
        useReportApi();
    const { setNextEnabled } = useReportContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const axiosPrivate = useAxiosPrivate();
    const { editedEquipment } = usePlaygroundReportEquipmentContext();

    const [restrictions, setRestrictions] = useState([]);

    setNextEnabled(true);

    useEffect(() => {
        fetchReportEquipmentRestrictions();
    }, [editedEquipment]);

    function fetchReportEquipmentRestrictions() {
        getReportEquipmentRestrictions(editedEquipment.id, (response) => {
            const reportEquipmentRestrictions =
                response.data.reportEquipmentRestrictions;
            if (reportEquipmentRestrictions.length === 0) {
                fetchEquipmentTypeRestrictions();
            } else {
                const restrictions = reportEquipmentRestrictions.map(
                    (restriction, index) => {
                        return {
                            id: index,
                            reportRestrictionId: restriction.id,
                            name: restriction.name,
                            value: restriction.value,
                        };
                    }
                );
                setRestrictions(restrictions);
            }
        });
    }

    function fetchEquipmentTypeRestrictions() {
        getEquipmentTypeRestrictionsByType(
            editedEquipment.equipment.type.id,
            (response) => {
                const equipmentTypeRestrictions =
                    response.data.equipmentTypeRestrictions;
                const restrictions = equipmentTypeRestrictions.map(
                    (restriction, index) => {
                        saveReportEquipmentRestrictions({
                            name: restriction.name,
                            value: restriction.value,
                        });
                        return {
                            id: index,
                            name: restriction.name,
                            value: restriction.value,
                        };
                    }
                );
                setRestrictions(restrictions);
            }
        );
    }

    const saveReportEquipmentRestrictions = async (params) => {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            let response;
            if (isNaN(params.id)) {
                const request = {
                    name: params.name,
                    value: params.value,
                };
                response = await axiosPrivate.post(
                    `${REPORT_URL}/reportequipment/${editedEquipment.id}/restriction`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                );
            } else {
                const request = {
                    id: params.id,
                    name: params.name,
                    value: params.value,
                };
                response = await axiosPrivate.put(
                    `${REPORT_URL}/reportequipment/${editedEquipment.id}/restriction/${params.id}`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                );
            }
            fetchReportEquipmentRestrictions();
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    };

    function deleteRestriction(id) {
        deleteReportEquipmentRestriction(id, (report) => {
            fetchReportEquipmentRestrictions(false);
        });
    }

    return (
        <ReportEquipmentRestrictionsContext.Provider
            value={{
                restrictions,
                deleteRestriction,
                saveReportEquipmentRestrictions,
            }}
        >
            {children}
        </ReportEquipmentRestrictionsContext.Provider>
    );
};

export default ReportEquipmentRestrictionsContext;
