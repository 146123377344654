import { EquipmentProvider } from "../../../basicdata/equipment/EquipmentContext";
import { ManufacturerProvider } from "../../../basicdata/manufacturer/ManufacturerContext";
import { PlaygroundReportEquipmentsByManufacturerProvider } from "../equipmentsByManufacturer/PlaygroundReportEquipmentsByManufacturerContext";
import { PlaygroundReportEquipmentsProvider } from "../PlaygroundReportEquipmentsContext";
import EntertainmentReportEquipment from "./EntertainmentReportEquipmentPage";

const PlaygroundReportEquipmentsPage = () => {
    return (
        <PlaygroundReportEquipmentsProvider>
            <EquipmentProvider>
                <ManufacturerProvider>
                    <PlaygroundReportEquipmentsByManufacturerProvider>
                        <EntertainmentReportEquipment />
                    </PlaygroundReportEquipmentsByManufacturerProvider>
                </ManufacturerProvider>
            </EquipmentProvider>
        </PlaygroundReportEquipmentsProvider>
    );
};

export default PlaygroundReportEquipmentsPage;
