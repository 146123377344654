import { createContext, useEffect, useState } from "react";
import { REPORT_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import useReportContext from "../useReportContext";
import { useReportApi } from "../../../api/reportApi";
import useReportImageContext from "../useReportImagesContext";

const PlaygroundReportEquipmentContext = createContext({});

export const EditType = {
    None: "None",
    Restrictions: "Restrictions",
    Attributes: "Attributes",
    Issues: "Issues",
};

export const PlaygroundReportEquipmentProvider = ({ children }) => {
    const { images, fetchImages } = useReportImageContext();
    const { getReportEquipments } = useReportApi();
    const { report, setNextEnabled } = useReportContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDialog, setShowDialog] = useState(false);
    const [equipments] = useState(report.reportEquipments);
    const [editType, setEditType] = useState(EditType.None);
    const [editedEquipment, setEditedEquipment] = useState(null);
    const [reportEquipments, setReportEquipments] = useState([]);

    function editNextEquipment(id) {
        const currentIndex = equipments.findIndex((item) => item.id === id);
        if (currentIndex < equipments.length - 1) {
            setEditedEquipment(equipments[currentIndex + 1]);
        } else {
            setEditedEquipment(equipments[0]);
        }
    }

    function setEditedId(id) {
        const equipment = equipments.find((item) => item.id === id);
        setEditedEquipment(equipment);
    }

    function refreshReportEquipments() {
        fetchReportEquipments();
    }

    useEffect(() => {
        if (report.baseData.id && editType === EditType.None) {
            refreshReportEquipments();
        }
    }, [editType]);

    useEffect(() => {
        if (report.baseData.id) {
            fetchImages(true);
            refreshReportEquipments();
        }
    }, [report]);

    useEffect(() => {
        setNextEnabled(
            (reportEquipments.length > 0 ? true : false) &&
                reportEquipments.every(
                    (item) => typeof item.image !== "undefined"
                )
        );
    }, [reportEquipments, setReportEquipments]);

    function fetchReportEquipments() {
        getReportEquipments(report.baseData.id, (response) => {
            const equipments = response.data.reportEquipments.map(
                (item, index) => ({
                    ...item,
                    reportEquipmentId: index,
                })
            );
            setReportEquipments(equipments);
        });
    }

    const saveReportEquipments = async () => {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            const equipments = reportEquipments.map((item) => {
                return {
                    reportEquipmentId: item.reportEquipmentId,
                    equipmentId: item.equipment.id,
                    inspectionType: item.inspectionType.id,
                    certificateNumber: item.equipment.certificateNumber,
                    certificateDate: item.equipment.certificateDate,
                    image: item.equipment.image,
                };
            });
            const request = { equipments: equipments };
            await axiosPrivate.put(
                `${REPORT_URL}/${report.baseData.id}/equipments`,
                JSON.stringify(request),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    };

    return (
        <PlaygroundReportEquipmentContext.Provider
            value={{
                report,
                images,
                showDialog,
                setShowDialog,
                reportEquipments,
                setReportEquipments,
                editedEquipment,
                setEditedEquipment,
                editType,
                editNextEquipment,
                setEditType,
                setEditedId,
                saveReportEquipments,
                refreshReportEquipments,
            }}
        >
            {children}
        </PlaygroundReportEquipmentContext.Provider>
    );
};

export default PlaygroundReportEquipmentContext;
