import TextField from "@mui/material/TextField";
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEquipmentApi } from "../../../api/equipmentApi";
import useManufacturerContext from "../manufacturer/useManufacturerContext";
import { useEffect, useState } from "react";
import { useManufacturerApi } from "../../../api/manufacturerApi";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { useEquipmentTypeApi } from "../../../api/equipmentTypeApi";
import { useCertificateIssuerApi } from "../../../api/certificateIssuerApi";

const emptyErrors = {
    name: null,
    csv: null,
    certificateNumber: null,
    category: null,
    manufacturerDeclarationNumber: null,
};

const emptyEquipment = {
    id: null,
    name: null,
    typeNumber: null,
    certificateNumber: null,
    certificateDate: null,
    certificateIssuer: null,
    category: null,
    type: null,
    manufacturer: null,
    active: true,
    csv: null,
    manufacturerDeclarationNumber: null,
};

function EquipmentEditDialogContent({
    equipmentId,
    manufacturer,
    showNewManufacturerButton,
    equipmentCategory,
    onDataChanged,
}) {
    const { setShowNewManufacturerDialog } = useManufacturerContext();
    const { getCertificateIssuers } = useCertificateIssuerApi();
    const { getEquipmentTypes } = useEquipmentTypeApi();
    const { getEquipmentCategories } = useEquipmentCategoryApi();
    const { getManufacturers } = useManufacturerApi();
    const { getEquipment } = useEquipmentApi();

    const [manufacturers, setManufacturers] = useState([]);
    const [certificateIssuers, setCertificateIssuers] = useState([]);
    const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [equipmentCategories, setEquipmentCategories] = useState([]);

    const [equipmentData, setEquipmentData] = useState();
    const [errors, setErrors] = useState(emptyErrors);

    const hasError =
        equipmentData == null ||
        equipmentData == emptyEquipment ||
        (equipmentData != null &&
            (hasPlaygroundError() ||
                hasSportEquipmentError() ||
                hasEntertainmentEquipmentError()));

    useEffect(() => {
        onDataChanged(hasError, equipmentData);
    }, [equipmentData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Wrap callback-based functions in Promises and use Promise.all
                const [
                    manufacturers,
                    certificateIssuers,
                    equipmentCategories,
                    equipmentTypes,
                ] = await Promise.all([
                    fetchManufacturersPromise(),
                    fetchCertificateIssuersPromise(),
                    fetchEquipmentCategoriesPromise(),
                    fetchEquipmentTypesPromise(),
                ]);

                setManufacturers(manufacturers.data.manufacturers);
                setCertificateIssuers(
                    certificateIssuers.data.certificateIssuers
                );
                setAllEquipmentTypes(equipmentTypes.data.equipmentTypes);
                setEquipmentCategories(
                    equipmentCategories.data.equipmentCategories
                );
            } catch (err) {
                console.error(err);
            } finally {
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (equipmentId) {
            getEquipment(equipmentId, (response) => {
                setEquipmentData(response.data.equipment);
            });
        } else {
            //setEquipmentData(emptyEquipment);
        }
    }, [equipmentId]);

    useEffect(() => {
        if (
            equipmentCategories.length > 0 &&
            equipmentCategory &&
            equipmentData?.category !== equipmentCategory
        ) {
            const event = {};
            event.target = {};
            event.target.type = "autocomplete";
            event.target.name = "category";
            event.target.selectedOption = equipmentCategory;
            handleChange(event);
        }
    }, [equipmentCategory, equipmentData, equipmentCategories]);

    useEffect(() => {
        if (manufacturer && equipmentData?.manufacturer !== manufacturer) {
            const event = {};
            event.target = {};
            event.target.type = "autocomplete";
            event.target.name = "manufacturer";
            event.target.selectedOption = manufacturer;
            handleChange(event);
        }
    }, [manufacturer, equipmentData]);

    useEffect(() => {
        if (equipmentData?.category != null && allEquipmentTypes.length > 0) {
            filterEquipmentTypes(equipmentData.category);
        }
    }, [equipmentData, allEquipmentTypes]);

    function hasPlaygroundError() {
        return (
            equipmentData.category?.id === 1 &&
            (!equipmentData.name ||
                !equipmentData.category ||
                errors.name ||
                errors.category ||
                !equipmentData.type ||
                equipmentData.certificateNumber === null ||
                equipmentData.certificateNumber?.trim() === "" ||
                errors.manufacturerDeclarationNumber)
        );
    }

    function hasSportEquipmentError() {
        return (
            equipmentData.category?.id === 2 &&
            (!equipmentData.name ||
                !equipmentData.category ||
                errors.name ||
                errors.category ||
                !equipmentData.type ||
                equipmentData.certificateNumber?.trim() === "" ||
                equipmentData.manufacturerDeclarationNumber === null)
        );
    }

    function hasEntertainmentEquipmentError() {
        return (
            equipmentData.category?.id === 3 &&
            (!equipmentData.name ||
                !equipmentData.category ||
                errors.name ||
                errors.category ||
                !equipmentData.type ||
                equipmentData.manufacturingDate?.length !== 4)
        );
    }

    function validate(name, value) {
        if (!equipmentData) return;

        switch (name) {
            case "name":
                break;
            case "category":
                filterEquipmentTypes(value);
                setCertificateIssuer(value);
                break;
            case "manufacturerDeclarationNumber":
            case "certificateNumber": {
                if (!value || value.trim() === "") {
                    setErrors({
                        ...errors,
                        [name]: "Kötelező mező",
                    });
                } else {
                    setErrors({
                        ...errors,
                        [name]: null,
                    });
                }
                break;
            }
            case "manufacturer":
                if (!value) {
                }
                break;
            default:
                break;
        }
    }

    function filterEquipmentTypes(category) {
        const equipmentTypes = allEquipmentTypes.filter(
            (type) => category.id === type.category.id
        );
        setEquipmentTypes(equipmentTypes);
    }

    function setCertificateIssuer(category) {
        if (category.id === 3) {
            equipmentData.certificateIssuer = certificateIssuers.find(
                (issuer) => issuer.name.includes("HEXON-GOLD")
            );
        } else {
            equipmentData.certificateIssuer = null;
        }
    }
    const handleCertificateIssueDate = (date) => {
        setEquipmentData((prevData) => ({
            ...prevData,
            certificateDate: date,
        }));
    };

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setEquipmentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleManufacturingDateChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            // Allow only numeric characters
            handleChange(event);
        }
    };

    async function fetchEquipmentCategoriesPromise() {
        return new Promise((resolve, reject) => {
            getEquipmentCategories((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error("Failed to fetch equipments"));
                }
            });
        });
    }

    async function fetchEquipmentTypesPromise() {
        return new Promise((resolve, reject) => {
            getEquipmentTypes((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error("Failed to fetch equipments"));
                }
            });
        });
    }

    async function fetchManufacturersPromise() {
        return new Promise((resolve, reject) => {
            getManufacturers((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error("Failed to fetch manufacturers"));
                }
            });
        });
    }

    async function fetchCertificateIssuersPromise() {
        return new Promise((resolve, reject) => {
            getCertificateIssuers((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error("Failed to fetch certificateIssuers"));
                }
            });
        });
    }

    const title =
        equipmentData?.id == null ? "Új eszköz" : "Eszköz szerkesztés";

    if (equipmentData != null) {
        return (
            <Box>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentData?.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Cikkszám"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentData?.typeNumber}
                    error={errors.typeNumber != null}
                    helperText={errors.typeNumber}
                    name="typeNumber"
                />
                <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                    <Autocomplete
                        sx={{ width: "50%" }}
                        id="free-solo-demo"
                        name="category"
                        value={equipmentData.category}
                        disabled={equipmentCategory != null}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "category";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={equipmentCategories}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszköz kategória *" />
                        )}
                    />
                    <Autocomplete
                        sx={{ width: "50%" }}
                        id="free-solo-demo"
                        name="equipmentType"
                        value={equipmentData.type}
                        disabled={equipmentTypes.length === 0}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "type";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={equipmentTypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszköz típus" />
                        )}
                    />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                    <TextField
                        sx={{ width: "75%" }}
                        id="outlined-basic name"
                        label="Tanúsítvány szám"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        onChange={handleChange}
                        value={equipmentData.certificateNumber}
                        error={errors.certificateNumber != null}
                        helperText={errors.certificateNumber}
                        name="certificateNumber"
                        required={equipmentData?.category?.id === 1}
                    />
                    <DesktopDatePicker
                        sx={{ width: "25%" }}
                        label="Kibocsátás"
                        inputFormat="YYYY.MM.DD"
                        value={equipmentData.certificateDate}
                        onChange={handleCertificateIssueDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
                <Autocomplete
                    sx={{ width: "100%", my: 2 }}
                    id="free-solo-demo"
                    name="certificateIssuer"
                    value={equipmentData.certificateIssuer}
                    disabled={equipmentData?.category?.id === 3}
                    onChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "certificateIssuer";
                            event.target.selectedOption = newInputValue;
                            handleChange(event);
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                    }
                    options={certificateIssuers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Tanúsítvány kibocsátó" />
                    )}
                />
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        id="free-solo-demo"
                        name="manufacturer"
                        value={equipmentData.manufacturer}
                        disabled={manufacturer != null}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "manufacturer";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={manufacturers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Gyártó" />
                        )}
                    />
                    {equipmentData?.category?.id === 3 && (
                        <TextField
                            sx={{ width: "25%" }}
                            id="outlined-basic name"
                            label="Gyártás éve"
                            variant="outlined"
                            type="text"
                            autoComplete="off"
                            inputProps={{
                                maxLength: 4,
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                            }}
                            onChange={handleManufacturingDateChange}
                            value={equipmentData.manufacturingDate}
                            helperText={errors.manufacturingDate}
                            name="manufacturingDate"
                        />
                    )}
                    {showNewManufacturerButton && (
                        <Button
                            variant="outlined"
                            name="new"
                            onClick={() => setShowNewManufacturerDialog(true)}
                        >
                            Új gyártó
                        </Button>
                    )}
                </Stack>
                {equipmentData?.category?.id === 2 && (
                    <TextField
                        sx={{ width: "100%" }}
                        id="outlined-basic name"
                        label="Gyártói nyilatkozat száma"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        onChange={handleChange}
                        value={equipmentData.manufacturerDeclarationNumber}
                        error={errors.manufacturerDeclarationNumber != null}
                        helperText={errors.manufacturerDeclarationNumber}
                        name="manufacturerDeclarationNumber"
                        required={equipmentData?.category?.id === 2}
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            name="active"
                            onChange={handleChange}
                            checked={equipmentData.active}
                        />
                    }
                    label="Aktív"
                />
            </Box>
        );
    }
}

export default EquipmentEditDialogContent;
