// Updated EntertainmentReportEquipment Component
import { Select, MenuItem, Typography, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import usePlaygroundReportEquipmentsByManufacturerContext from "../equipmentsByManufacturer/usePlaygroundReportEquipmentsContext";
import useEquipmentContext from "../../../basicdata/equipment/useEquipmentContext";
import PrevNextButtons from "../../PrevNextButtons";
import useReportContext from "../../useReportContext";
import usePlaygroundReportEquipmentsContext from "../usePlaygroundReportEquipmentsContext";
import EquipmentEditDialogContent from "../../../basicdata/equipment/EquipmentEditDialogContent";
import { useEquipmentApi } from "../../../../api/equipmentApi";
import ManufacturerEditDialog from "../../../basicdata/manufacturer/ManufacturerEditDialog";

function EntertainmentReportEquipment() {
    const { saveEquipment } = useEquipmentApi();
    const {
        fetchManufacturers,
        selectedManufacturer,
        setSelectedManufacturer,
    } = usePlaygroundReportEquipmentsByManufacturerContext();
    const {
        reportEquipments,
        saveReportEquipments,
        inspectionTypes,
        changeInspectionType,
    } = usePlaygroundReportEquipmentsContext();
    const { setShowEquipmentEditDialog } = useEquipmentContext();
    const { reportConfig, setNextEnabled } = useReportContext();

    const [inspectionType, setInspectionType] = useState(null);
    const [comment, setComment] = useState("");

    const [data, setData] = useState(null);
    const [hasError, setHasError] = useState(false);

    setNextEnabled(!hasError);

    useEffect(() => {
        setShowEquipmentEditDialog(reportEquipments.length === 0);
        if (selectedManufacturer == null && reportEquipments.length === 1) {
            setSelectedManufacturer(reportEquipments[0].equipment.manufacturer);
        }
    }, [reportEquipments]);

    useEffect(() => {
        if (reportEquipments.length === 1) {
            const singleItem = reportEquipments[0];
            setInspectionType(singleItem.inspectionType || null);
            setData(singleItem.equipment);
            setComment(singleItem.comment);
        }
    }, [reportEquipments]);

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Typography variant="h5">Eszköz adatai</Typography>
            <EquipmentEditDialogContent
                equipmentId={reportEquipments[0]?.equipment?.id}
                showNewManufacturerButton={true}
                equipmentCategory={reportConfig.equipmentCategory}
                onDataChanged={(hasError, equipment) => {
                    setHasError(hasError);
                    setData(equipment);
                }}
            />
            <Stack
                direction="row"
                spacing={2}
                mt={2}
                justifyContent="space-between"
                alignItems="flex-start" // Align items to their natural height
            >
                <Select
                    value={inspectionType}
                    onChange={(event) => {
                        changeInspectionType(
                            reportEquipments[0],
                            event.target.value
                        );
                    }}
                    renderValue={(item) => item.name}
                    sx={{ minWidth: 300 }} // Optional: Set a specific width for the Select component
                >
                    {inspectionTypes.map((inspectionType) => (
                        <MenuItem
                            key={inspectionType.id}
                            value={inspectionType}
                        >
                            {inspectionType.name}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Megjegyzés"
                    multiline
                    rows={4} // Number of visible rows
                    placeholder="Megjegyzés"
                    variant="outlined"
                    fullWidth
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                />
            </Stack>

            <PrevNextButtons
                nextFunction={() => {
                    return saveEquipment(data)
                        .then(
                            saveReportEquipments(comment).then(
                                (success) => success
                            )
                        )
                        .then(() => true)
                        .catch((e) => false);
                }}
            />
            <ManufacturerEditDialog
                onClose={(manufacturer) => {
                    fetchManufacturers().then(() => {
                        setSelectedManufacturer(manufacturer);
                    });
                }}
            />
        </div>
    );
}

export default EntertainmentReportEquipment;
