import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentContext from "./useEquipmentContext";
import { useEquipmentApi } from "../../../api/equipmentApi";
import EquipmentEditDialogContent from "./EquipmentEditDialogContent";
import { useState } from "react";

function EquipmentEditDialog({
    equipmentId,
    manufacturer,
    equipmentCategory,
    onClose,
}) {
    const { saveEquipment } = useEquipmentApi();
    const { showEquipmentEditDialog, setShowEquipmentEditDialog } =
        useEquipmentContext();

    const [data, setData] = useState();
    const [hasError, setHasError] = useState(false);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveEquipment(data, (response) => {
                    onClose(response);
                });
                break;
            }
            default:
                break;
        }
        setShowEquipmentEditDialog(false);
    };

    const title = data && data.id == null ? "Új eszköz" : "Eszköz szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showEquipmentEditDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <EquipmentEditDialogContent
                    equipmentId={equipmentId}
                    manufacturer={manufacturer}
                    showNewManufacturerButton={false}
                    equipmentCategory={equipmentCategory}
                    onDataChanged={(hasError, equipment) => {
                        setHasError(hasError);
                        setData(equipment);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentEditDialog;
