import { PlaygroundReportEquipmentAttributesProvider } from "./attributes/PlaygroundReportEquipmentAttributesContext";
import PlaygroundReportEquipmentAttributesTable from "./attributes/PlaygroundReportEquipmentAttributesTable";
import { PlaygroundReportEquipmentIssuesProvider } from "./issues/PlaygroundReportEquipmentIssuesContext";
import PlaygroundReportEquipmentIssuesTable from "./issues/PlaygroundReportEquipmentIssuesTable";
import { EditType } from "./PlaygroundReportEquipmentContext";
import PlaygroundReportEquipmentList from "./PlaygroundReportEquipmentList";
import { ReportEquipmentRestrictionsProvider } from "./restrictions/ReportEquipmentRestrictionsContext";
import ReportEquipmentRestrictionsTable from "./restrictions/ReportEquipmentRestrictionsTable";
import usePlaygroundReportEquipmentContext from "./usePlaygroundReportEquipmentContext";

const PlaygroundReportEquipmentPageContent = () => {
    const { editType } = usePlaygroundReportEquipmentContext();

    const content = () => {
        switch (editType) {
            case EditType.None:
                return <PlaygroundReportEquipmentList />;
            case EditType.Restrictions:
                return (
                    <ReportEquipmentRestrictionsProvider>
                        <ReportEquipmentRestrictionsTable />
                    </ReportEquipmentRestrictionsProvider>
                );
            case EditType.Attributes:
                return (
                    <PlaygroundReportEquipmentAttributesProvider>
                        <PlaygroundReportEquipmentAttributesTable />
                    </PlaygroundReportEquipmentAttributesProvider>
                );
            case EditType.Issues:
                return (
                    <PlaygroundReportEquipmentIssuesProvider>
                        <PlaygroundReportEquipmentIssuesTable />
                    </PlaygroundReportEquipmentIssuesProvider>
                );
            default:
        }
    };

    return <div>{content()}</div>;
};

export default PlaygroundReportEquipmentPageContent;
