import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { EQUIPMENT_URL } from "./Url";

export function useEquipmentApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getEquipments(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${EQUIPMENT_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getEquipment(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${EQUIPMENT_URL}/${id}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getEquipmentsByManufacturerAndCategory(
        manufacturerId,
        categoryId,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${EQUIPMENT_URL}/list/manufacturer/${manufacturerId}/${categoryId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveEquipment(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                name: data.name,
                typeNumber: data.typeNumber,
                certificateNumber: data.certificateNumber,
                certificateDate: data.certificateDate?.valueOf() ?? null,
                certificateIssuer: data?.certificateIssuer?.id ?? null,
                category: data?.category?.id ?? null,
                type: data?.type?.id ?? null,
                manufacturer: data?.manufacturer?.id ?? null,
                active: data.active,
                manufacturerDeclarationNumber:
                    data.manufacturerDeclarationNumber,
                manufacturingDate: data.manufacturingDate,
            };
            if (data.id == null) {
                await axiosPrivate
                    .post(`${EQUIPMENT_URL}`, JSON.stringify(request), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${EQUIPMENT_URL}/${data.id}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function uploadEquipmentCsv(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data);

        try {
            await axiosPrivate
                .post(`${EQUIPMENT_URL}/list`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteEquipment(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${EQUIPMENT_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getEquipment,
        getEquipments,
        getEquipmentsByManufacturerAndCategory,
        saveEquipment,
        uploadEquipmentCsv,
        deleteEquipment,
    };
}
