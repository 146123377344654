import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
    DataGrid,
    GridToolbarContainer,
    GridRowModes,
    GridActionsCellItem,
} from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { EditType } from "../PlaygroundReportEquipmentContext";
import { randomId } from "@mui/x-data-grid-generator";
import { useCallback, useEffect, useState } from "react";
import PrevNextButtons from "../../PrevNextButtons";
import useReportEquipmentRestrictionsContext from "./useReportEquipmentRestrictionsContext";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";

function EditToolbar(props) {
    const { currentRow, setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [
            ...oldRows,
            { id, standard: {}, standardIssue: {}, image: null, isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "standard" },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClick}
                disabled={currentRow != null}
            >
                Új korlátozás
            </Button>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
};

function ReportEquipmentRestrictionsTable() {
    const { setEditType, editedEquipment } =
        usePlaygroundReportEquipmentContext();
    const { restrictions, saveReportEquipmentRestrictions, deleteRestriction } =
        useReportEquipmentRestrictionsContext();
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        setRows(restrictions);
    }, [restrictions]);

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (row) => () => {
        setCurrentRow(row);
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (row) => () => {
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.View },
        });
        setCurrentRow(null);
    };

    const handleDeleteClick = (row) => () => {
        setRows(rows.filter((r) => r.id !== row.id));
        deleteRestriction(row.reportRestrictionId);
        setCurrentRow(null);
    };

    const handleCancelClick = (row) => () => {
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((r) => r.id === row.id);
        if (editedRow.isNew) {
            setRows(rows.filter((r) => r.id !== row.id));
        }
        setCurrentRow(null);
    };

    const navigateToEquipmentList = (id) => {
        setEditType(EditType.None);
    };

    const columns = [
        {
            field: "name",
            headerName: "Megnevezés",
            minWidth: 70,
            flex: 0.3,
            editable: true,
        },
        {
            field: "value",
            headerName: "Érték",
            minWidth: 70,
            flex: 0.4,
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (params) => {
                const id = params.id;
                const isInEditMode =
                    rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(params.row)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.row)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(params.row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(params.row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const processRowUpdate = useCallback(
        async (newRow) => {
            const updatedRow = {
                id: newRow.reportRestrictionId,
                name: newRow.name,
                value: newRow.value,
            };
            saveReportEquipmentRestrictions(updatedRow);
            return updatedRow;
        },
        [rows]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Stack
                direction="row"
                spacing={2}
                m={2}
                justifyContent="space-between"
            >
                <Typography variant="h5">
                    {editedEquipment?.equipment?.name} korlátozásai
                </Typography>
            </Stack>
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        currentRow,
                        setRows,
                        setRowModesModel,
                    },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            <PrevNextButtons
                prevFunction={() => navigateToEquipmentList()}
                nextLabel="Tovább"
                nextFunction={() => navigateToEquipmentList()}
            />
        </div>
    );
}

export default ReportEquipmentRestrictionsTable;
