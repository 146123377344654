import { createContext, useState, useLayoutEffect, useEffect } from "react";
import dayjs from "dayjs";
import { useEquipmentApi } from "../../../api/equipmentApi";

const EquipmentContext = createContext({});

export const EquipmentProvider = ({ children }) => {
    const { getEquipments, uploadEquipmentCsv, deleteEquipment } =
        useEquipmentApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEquipmentEditDialog, setShowEquipmentEditDialog] =
        useState(false);

    const [equipments, setEquipments] = useState([]);

    const [selectedEquipment, setSelectedEquipment] = useState();

    const showTableEditButtons = selectedEquipment?.id == null ? false : true;

    useEffect(() => {
        fetchEquipments();
    }, []);

    function selectEquipment(id) {
        if (id != null) {
            const equipment = equipments.find(
                (equipment) => equipment.id === id
            );
            equipment.certificateDate = equipment.certificateDate
                ? dayjs(equipment.certificateDate)
                : null;
            setSelectedEquipment(equipment);
        } else {
            setSelectedEquipment(null);
        }
    }

    function fetchEquipments() {
        getEquipments((response) => {
            setEquipments(response.data.equipments);
        });
    }

    function uploadCsv() {
        uploadEquipmentCsv(selectEquipment.csv, (response) => {
            fetchEquipments();
        });
    }

    function deleteEquip() {
        deleteEquipment(selectEquipment.id, (response) => {
            fetchEquipments();
        });
    }

    return (
        <EquipmentContext.Provider
            value={{
                fetchEquipments,
                selectedEquipment,
                setSelectedEquipment,
                showEquipmentEditDialog,
                setShowEquipmentEditDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipments,
                setEquipments,
                selectEquipment,
                deleteEquip,
                uploadCsv,
                showTableEditButtons,
            }}
        >
            {children}
        </EquipmentContext.Provider>
    );
};

export default EquipmentContext;
